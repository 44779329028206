.boxResponsive{
    
    padding: 30px;
    
    display: flex;
    flex-direction: column;
}

.mt-5{
    
}