button{
    border:none;
    background-color: transparent   ;
}



.centered{
    display: flex;
    
}

.centeredFlex{
    display: flex;
    justify-content:center ;
    
}