body{
    font-family: 'Montserrat', sans-serif;
    
}
.buttonClass{
    display: block;
    height: 50px;
    color: red;
    margin-bottom: 50px;
}


#error{
    color:rgb(0, 0, 0);
    background-color: red;
    display: flex;
    flex-wrap: wrap; 
    justify-content: center;
}


.datosApi{

    border-style: double;
    font-size: small;
    margin:1%;
    text-align: center;
    width: 14rem;
    font-weight: 500;
    border:2px solid black
    
}

#imgT{


    clip-path: circle(45.0% at 50% 50%);
}

#infMembresia ,#infContacto,#infAdicional{
    display: none;
}

.nombre{
    font-family: montse;
}

@keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(-10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }


  #infPersonal{
    color: red;
  }

  .test{
    margin-left: 25px;
    padding: 15px;
    
    background-color:rgba(209,213,219);
    
    
  }