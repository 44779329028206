
.css-1q1u3t4-MuiTableRow-root{
height: 1px !important;
}

.center-snackbar {
    text-align: center !important;
  }

  .priceCard{

    margin-top: 30px;
    --border-right: solid 0.1px palevioletred;
    --border-bottom: solid 0.1px palevioletred;
    --background-color: rgb(29, 80, 131);
    box-shadow: 0 10px 10px -5px;

  }

html,body{
   
    background-color:#CCD0D5;
    color: #314179;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif ;
    padding: 0px;
}

.border_bt{
    border-bottom:  0.1px solid rebeccapurple;
}

.cont-table-sedes{
    padding: 15px;
    --width: 540px;
    --height: auto;

}




.testClass{
    background-color: darkblue;
}

.containerSedes{
    margin-left: 240px;
    margin-top: 100px;
    margin-right: 15px;
}

.pointer{
    cursor: pointer;
}

.myButton {
	box-shadow:inset 0px 1px 0px 0px #7a8eb9;
	background:linear-gradient(to bottom, #637aad 5%, #5972a7 100%);
	background-color:#637aad;
	border:1px solid #314179;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:13px;
	font-weight:bold;
	padding:6px 12px;
	text-decoration:none;
}
.myButton:hover {
	background:linear-gradient(to bottom, #5972a7 5%, #637aad 100%);
	background-color:#5972a7;
}
.myButton:active {
	position:relative;
	top:1px;
}

