.bigbox{
    max-width: auto;
    padding: 15px;
border-radius: 5px;
    display: flex;
    flex-direction: row;
    
    margin: 1rem;
    box-shadow: 0px 4px 8px rgba(32, 30, 30, 0.2);
    
}
.bigbox button{
    margin: 1px;

}

